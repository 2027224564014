body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  display: inline-block;
}

.App-logo {
  height: 15vmin;
}

.Prediction-bar { 
  line-height: 100px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.scroll { 
  height: 400px;
  overflow: scroll;
  overflow-x: hidden;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.buttonLink { 
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius:5px;
  background:rgba(0,0,0,0.1);
}

::-webkit-scrollbar-thumb {
  border-radius:5px;
  background:rgba(0,0,0,0.2);
}

::-webkit-scrollbar-thumb:hover {
  background:rgba(0,0,0,0.4);
}

::-webkit-scrollbar-thumb:window-inactive {
  background:rgba(0,0,0,0.05);
}

.container {
  border: 2px solid black;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.pred_box { 
  background-color: #a6e3e3;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 10px;
}

.pred_row { 
  padding-left: 8px;
}

.header_title {
  margin-left: 14px;
  font-size: 19px;
}

input[type="radio"] {
  margin-right: 10px;
}

#root {
  margin-top: 10px;
  margin-left: 20px;
}

a {
  white-space: nowrap;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

